.btn-success {
  background-color: #21CD9A !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 5px !important;
  gap: 10px !important;
}

.continue:hover {
  background-color: #2bc093;
}

.user-input {
  background-color: #fafafa !important;
  border: 1px solid #bfbfbf !important;
  border-radius: 5px !important;
  padding: 7px !important;
  gap: 10px !important;
}

.new-user {
  font-size: 16px;
  color: #21CD9A;
  padding: 20px;
  text-decoration: none;
  font-weight: 700;
}

.new-user:hover {
  color: #2bc093;
}

.header-img {
  width: 30px !important;
  height: 30px !important;
}

h3 {
  font-size: 24px;
  color: #121111;
  font-weight: 600;
}