img {
  width: 335px !important;
  height: 304px !important;
  object-fit: cover;
}

p.head {
  font-size: 18px;
  color: #6b6b6b;
  font-weight: 600;
}

p.product {
  font-size: 16px;
  font-weight: 400;
}

p.quantity {
  font-size: 14px;
  font-weight: 400;
}

p.price {
  font-size: 14px;
  font-weight: 700;
}

.proceed {
  background-color: #21CD9A;
  color: #ffffff;
  border: none;
  border-radius: 24px;
}

.cancel {
  background-color:#CD21211A;
  color: #CD2121;
}