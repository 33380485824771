.date {
  border: 1px solid #21CD9A !important;
}

.currency {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #bfbfbf;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 5px;
  margin-right: 20px;
}