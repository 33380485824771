p {
  font-size: 18px;
  color: #6b6b6b;
  font-weight: 600;
}

p.verification {
  color: #111111;
  font-weight: 400;
  font-size: 16px;
  margin-top: 60px;
}

p.code {
  font-size: 16px;
  color: #21CD9A;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
}

.otp-input {
  width: 40px !important;
  padding: 10px;
  border: 1px solid #BFBFBF;
  background-color: #FAFAFA;
  border-radius: 5px;
  margin: 8px;
}

.continue {
  margin-top: 100px;
}