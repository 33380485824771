.head {
  color: #111111;
  font-weight: 600;
  margin-top: 210px;
  font-size: 18px;
}

.content {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.insert {
  color: #21CD9A;
}

button {
  background-color: #21CD9A;
  color: #ffffff;
  border: none;
  padding: 14px 64px;
  border-radius: 24px;
  gap: 10px;
  text-decoration: none;
}

button:hover {
  background-color: #16a57a;
}

button a {
  text-decoration: none;
  color: white;
}

button a:hover {
  color: white;
}