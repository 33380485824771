* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Overpass',
  sans-serif !important;
}

body {
  background: linear-gradient(180deg, rgba(33, 205, 154, 0.99) 0%, rgba(33, 205, 154, 0.9) 100%), url('./assets/img/bg2.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
}

p {
  color: #6B6B6B;
  font-weight: 600;
  font-size: 18px;
}

label {
  color: #111111;
  font-weight: 400;
  font-size: 16px;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.card {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}